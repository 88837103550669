fieldset[disabled] .form-control, .form-control-wrapper .form-control, .form-control {
  &, &:focus, &.focus {
    &:not(textarea):not(select) {
      height: 28px;
    }
    padding: 0;
    float: none;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    &:disabled {
      border-style: dashed;
      border-bottom: 1px solid #757575;
    }
  }
}
select.form-control {
  height: 23px;
}
select[multiple].form-control {
  &, &:focus, &.focus {
    height: 85px;
  }
}

.form-control {
  border: 0;
  background-image: linear-gradient(@primary, @primary), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(~"100% - 1px");
  background-color: transparent;
  background-color: rgba(0,0,0,0);
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: transparent;
  background-color: rgba(0,0,0,0);
}
fieldset[disabled] .form-control:disabled, .form-control-wrapper .form-control:disabled, .form-control:disabled, fieldset[disabled] .form-control:focus:disabled, .form-control-wrapper .form-control:focus:disabled, .form-control:focus:disabled, fieldset[disabled] .form-control.focus:disabled, .form-control-wrapper .form-control.focus:disabled, .form-control.focus:disabled {
  border: 0;
}

.form-control:focus, .form-control.focus {
  outline: none;
  background-image: linear-gradient(@primary, @primary), linear-gradient(#D2D2D2, #D2D2D2);
  animation: input-highlight 0.5s forwards;
  box-shadow: none;
  background-size: 0 2px, 100% 1px;
}


.form-control-wrapper {
  position: relative;

  .floating-label {
    color: #7E7E7E;
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 5px;
    transition: 0.2s ease all;
    opacity: 0;
  }
  .form-control:not(.empty) ~ .floating-label {
    top: -10px;
    font-size: 10px;
    opacity: 1;
  }
  .form-control:focus:invalid ~ .floating-label, .form-control.focus:invalid ~ .floating-label {
    color: @input-danger;
  }
  .form-control:focus ~ .material-input:after, .form-control.focus ~ .material-input:after {
    background-color: @input-default;
  }
  .form-control:focus:invalid ~ .material-input, .form-control.focus:invalid ~ .material-input {
    &:before, &:after  {
      background-color: @input-danger;
    }
  }
  .form-control.empty ~ .floating-label {
    opacity: 1;
  }
  textarea { resize: none; }
  textarea ~ .form-control-highlight {
    margin-top: -11px;
  }

  // Hints
  .hint {
    position: absolute;
    font-size: 80%;
    display: none;
  }
  .form-control:focus ~ .hint, .form-control.focus ~ .hint {
    display: block;
  }

  select ~ .material-input:after {
    display: none;
  }

}

.form-group {
  &.has-warning {
    .form-control {
      box-shadow: none;
    }
    .material-input:focus, .form-control:focus, .form-control.focus {
      background-image: linear-gradient(@input-warning, @input-warning), linear-gradient(#D2D2D2, #D2D2D2);
      box-shadow: none;
    }
    .control-label, input.form-control:not(.empty) ~ .floating-label {
      color: @input-warning;
    }
  }
  &.has-error {
    .form-control {
      box-shadow: none;
    }
    .material-input:focus, .form-control:focus, .form-control.focus {
      background-image: linear-gradient(@input-danger, @input-danger), linear-gradient(#D2D2D2, #D2D2D2);
      box-shadow: none;
    }
    .control-label, input.form-control:not(.empty) ~ .floating-label {
      color: @input-danger;
    }
  }
  &.has-success {
    .form-control {
      box-shadow: none;
    }
    .material-input:focus, .form-control:focus, .form-control.focus {
      background-image: linear-gradient(@input-success, @input-success), linear-gradient(#D2D2D2, #D2D2D2);
      box-shadow: none;
    }
    .control-label, input.form-control:not(.empty) ~ .floating-label {
      color: @input-success;
    }
  }
  &.has-info {
    .form-control {
      box-shadow: none;
    }
    .material-input:focus, .form-control:focus, .form-control.focus {
      background-image: linear-gradient(@input-info, @input-info), linear-gradient(#D2D2D2, #D2D2D2);
      box-shadow: none;
    }
    .control-label, input.form-control:not(.empty) ~ .floating-label {
      color: @input-info;
    }
  }
  .generic-variations(~" .form-control:focus", @primary, {
    background-image: linear-gradient(@material-color, @material-color), linear-gradient(#D2D2D2, #D2D2D2);
  });
  .generic-variations(~" .form-control.focus", @primary, {
    background-image: linear-gradient(@material-color, @material-color), linear-gradient(#D2D2D2, #D2D2D2);
  });
  .variations(~" .control-label", color, @lightbg-text);
  .variations(~" input.form-control:not(.empty) ~ .floating-label", color, @input-default);

}

.input-group {
  .form-control-wrapper {
    .form-control {
      float: none;
    }
    margin-right: 5px;
    margin-left: 5px;
  }
  .input-group-addon {
    border: 0;
    background: transparent;
  }
  .input-group-btn .btn {
    border-radius: 4px;
    margin: 0;
  }
}

select.form-control {
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #757575;
  border-radius: 0;
  &:focus, &.focus {
    box-shadow: none;
    border-color: #757575;
  }
}

@keyframes input-highlight {
  0% {
    background-size: 0 2px, 100% 1px;
  }

  100% {
    background-size: 100% 2px, 100% 1px;
  }
}

// Input files (kinda hack)
.form-control-wrapper input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
